import { nitrous } from '@motortrend/nitrous-web-sdk';

export type TargetingQueryParams = {
  dyn?: string;
  ppc?: string;
  sm_id?: string;
  test?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_id?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
  utm_type?: string;
};

export const getUtmParameters = (
  query: {
    [key: string]: string | string[] | undefined;
  } = {},
): TargetingQueryParams => {
  try {
    const nitrousUser = nitrous.user.getUser(); // Fetch user attributes

    const utm_campaign =
      query?.utm_campaign?.toString() ||
      nitrousUser?.userAttributes?.utm_campaign ||
      undefined;
    const utm_content =
      query?.utm_content?.toString() ||
      nitrousUser?.userAttributes?.utm_content ||
      undefined;
    const utm_id =
      query?.utm_id?.toString() ||
      nitrousUser?.userAttributes?.utm_id ||
      undefined;
    const utm_medium =
      query?.utm_medium?.toString() ||
      nitrousUser?.userAttributes?.utm_medium ||
      undefined;
    const utm_source =
      query?.utm_source?.toString() ||
      nitrousUser?.userAttributes?.utm_source ||
      undefined;
    const utm_term =
      query?.utm_term?.toString() ||
      nitrousUser?.userAttributes?.utm_term ||
      undefined;
    const utm_type =
      query?.utm_type?.toString() ||
      nitrousUser?.userAttributes?.utm_type ||
      undefined;

    return {
      ...(utm_campaign && { utm_campaign }),
      ...(utm_content && { utm_content }),
      ...(utm_id && { utm_id }),
      ...(utm_medium && { utm_medium }),
      ...(utm_source && { utm_source }),
      ...(utm_term && { utm_term }),
      ...(utm_type && { utm_type }),
    };
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const getClientUrlParams = (): { [key: string]: string } => {
  if (typeof window === 'undefined') {
    // If the window object is not available (i.e., not client-side), return an empty object.
    return {};
  }

  // Memoized object to prevent unnecessary recalculations
  const pairs: { [key: string]: string } = {};
  const searchParams = new URLSearchParams(window.location.search);

  // Efficiently iterate over the search parameters and store them in an object
  searchParams.forEach((value, key) => {
    pairs[key] = value;
  });

  const params = {
    ...(getUtmParameters(pairs) || {}),
  };

  ['dyn', 'ppc', 'sm_id', 'test'].forEach((key) => {
    const value = searchParams.get(key);
    if (value !== null) {
      params[key as keyof TargetingQueryParams] = value as string;
    }
  });

  return params;
};
