import { sendGrafanaEvent } from '../grafana/adsTrace';
import { getSessionData } from '../sessionData';

export const mark = (markName: string = 'pageload') => {
  performance.mark(`${markName}`);
  if (markName === 'pageload') {
    setTimeout(() => {
      if (getSessionData('mtAdRequested') !== 'true') {
        const eventName = 'SevereAdRequestDelay';
        const timing = markEnd(eventName);
        sendGrafanaEvent(eventName, { timing }, true);
      }
    }, 2000); // Send critical failure if ads are not requested in 2 sec from pageload
  }
};

export const clear = (markName: string) => {
  performance.clearMarks(`${markName}`);
};

export const markEnd = (markName: string): string => {
  if (performance.getEntriesByName('pageload', 'mark').length === 0) {
    mark();
  }
  performance.mark(`${markName}`);
  performance.measure(`${markName}-duration`, `pageload`, `${markName}`);
  const entries = performance.getEntriesByName(`${markName}-duration`);
  console.log(`${markName} duration:`, entries[0]?.duration);
  performance.clearMarks(`${markName}`);
  performance.clearMeasures(`${markName}-duration`);
  return entries[0]?.duration.toString() || 'NA';
};
