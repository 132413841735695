'use client';

import { adLog } from '@/utils/grafana/adsTrace';

export const handleGPTLoad = () => {
  adLog('GPT script loaded: true', {});
};

export const handleIASLoad = () => {
  adLog('IAS script loaded: true', {});
};

export const handleGPTErr = () => {
  adLog('GPT script loaded: false', {});
};

export const handleIASErr = () => {
  adLog('IAS script loaded: false', {});
};
