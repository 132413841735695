'use client';

import { useEffect, type FunctionComponent } from 'react';

import { GdprCategories } from '@/utils/onetrust/constants';
import loadScript from '@/utils/onetrust/loadScript';

const TrueAnthem: FunctionComponent = () => {
  useEffect(() => {
    try {
      loadScript('https://tru.am/scripts/custom/enthusiastnetwork.js', [
        GdprCategories.SOCIAL,
      ]);
    } catch (error) {
      console.error('Error injecting True Anthem script:', error);
    }
  }, []);
  return null;
};

export default TrueAnthem;
