// Bots identified with Vercel Monitoring. Ex: https://vercel.com/mtg-devs/~/monitoring?visualize=sum%28requests%29&groupBy=bot_name&title=Template%3A+Requests+by+Bot%2FCrawler
export const botUserAgentSubstrings = [
  'CCBot',
  'ChatGPT-User',
  'GPTBot',
  'Google-Extended',
  'anthropic-ai',
  'PerplexityBot',
  'OmigiliBot',
  'Amazonbot',
  'proximic',
  'GrapeshotCrawler',
  'bingbot',
  'facebookexternalhit',
  'AhrefsBot',
  'Googlebot',
  'SemrushBot',
  'ArchiveBot',
  'outbrain',
  'Pinterestbot',
  'Applebot',
  'AdsBot-Google',
  'Bytespider',
];

// Bots identified from Next.js Utility: https://github.com/vercel/next.js/blob/eb9f49b07e880965ca3cb0c7e091da8eabb76fd5/packages/next/src/server/web/spec-extension/user-agent.ts#L29-L33
export const nextJsBotSubstrings = [
  'Googlebot',
  'Mediapartners-Google',
  'AdsBot-Google',
  'googleweblight',
  'Storebot-Google',
  'Google-PageRenderer',
  'Google-InspectionTool',
  'Bingbot',
  'BingPreview',
  'Slurp',
  'DuckDuckBot',
  'baiduspider',
  'yandex',
  'sogou',
  'LinkedInBot',
  'bitlybot',
  'tumblr',
  'vkShare',
  'quora link preview',
  'facebookexternalhit',
  'facebookcatalog',
  'Twitterbot',
  'applebot',
  'redditbot',
  'Slackbot',
  'Discordbot',
  'WhatsApp',
  'SkypeUriPreview',
  'ia_archiver',
];

// These User Agents are not always bots, but were identified by AdOps to satisfy specific ad campaigns. AdOps will further manages the "bot-viewed" ad with the key-value pair 'bots=true', see: https://motortrendgroup.atlassian.net/browse/NTW-1150
// TODO: Serve list with S3 so it's editable by AdOps
export const additionalBotUserAgents = [
  'Mozilla/5.0 (iPad; CPU iPad OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4 Mobile/15E148 Safari/605.1.15 BingSapphire/1.0.420319002',
  'Mozilla/5.0 (iPad; CPU OS 15_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) EdgiOS/114.0.1823.82 Version/15.0 Mobile/15E148 Safari/604.1',
  'Mozilla/5.0 (iPad; CPU OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 [FBAN/FBIOS;FBAV/455.0.0.50.103;FBBV/575929547;FBDV/iPad11,7;FBMD/iPad;FBSN/iPadOS;FBSV/16.6.1;FBSS/2;FBID/tablet;FBLC/en_US;FBOP/5;FBRV/577261218]',
  'Mozilla/5.0 (iPad; CPU OS 17_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/117.0.5938.117 Mobile/15E148 Safari/604.1',
  'Mozilla/5.0 (iPad; CPU OS 17_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/288.0.576558892 Mobile/15E148 Safari/604.1',
  'Mozilla/5.0 (iPad; CPU OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21D61 [FBAN/FBIOS;FBAV/455.0.0.50.103;FBBV/575929547;FBDV/iPad14,5;FBMD/iPad;FBSN/iPadOS;FBSV/17.3.1;FBSS/2;FBID/tablet;FBLC/en_US;FBOP/5;FBRV/577690712]',
  'Mozilla/5.0 (iPad; CPU OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21D61 [FBAN/FBIOS;FBAV/455.0.0.50.103;FBBV/575929547;FBDV/iPad8,9;FBMD/iPad;FBSN/iPadOS;FBSV/17.3.1;FBSS/2;FBID/tablet;FBLC/en_US;FBOP/5;FBRV/577008070]',
  'Mozilla/5.0 (iPhone; CPU iPhone OS 15_8_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19H384 Instagram 323.0.3.23.54 (iPhone9,1; iOS 15_8_2; en_US; en; scale=2.00; 750x1334; 577210397)',
];
