/* eslint-disable @typescript-eslint/no-unused-vars */
'use client';

import { useEffect, type FunctionComponent } from 'react';
import { usePathname } from 'next/navigation';
import { withErrorBoundary } from 'react-error-boundary';

import initGoogletag from '@/utils/ads/googlePublisherTag/initGoogletag';
import handleImpressionViewableEvent from '@/utils/ads/googlePublisherTag/lifecycleCallbacks/handleImpressionViewableEvent';
import handleSlotOnLoad from '@/utils/ads/googlePublisherTag/lifecycleCallbacks/handleSlotOnLoad';
import handleSlotRenderEndedEvent from '@/utils/ads/googlePublisherTag/lifecycleCallbacks/handleSlotRenderEndedEvent';
import handleSlotRequested from '@/utils/ads/googlePublisherTag/lifecycleCallbacks/handleSlotRequested';
import handleSlotResponseReceived from '@/utils/ads/googlePublisherTag/lifecycleCallbacks/handleSlotResponseReceived';
import handleSlotVisibilityChangedEvent from '@/utils/ads/googlePublisherTag/lifecycleCallbacks/handleSlotVisibiltyChangedEvent';
import useResizeAds from '@/hooks/useResizeAds';
import useTriggerAdRefresh from '@/hooks/useTriggerAdRefresh';
import { NoUiErrorFallback } from '@/components/ErrorFallback';

const GPTSetup: FunctionComponent = () => {
  const pathname = usePathname();

  useEffect(() => {
    // Sets window.googletag equal to itself if it already exists or initialize
    // an object with an empty CommandArray `cmd`
    initGoogletag();
  }, [pathname]);

  useTriggerAdRefresh();

  // Clean up activity on route change. Not sure how this is different from
  // destroying each slot individually on unmount (below).
  useEffect(() => {
    googletag.cmd.push(function () {
      googletag.destroySlots();
    });
  }, [pathname]);

  useEffect(() => {
    googletag.cmd.push(function () {
      // The `slotRenderEnded` event listener is added in `Ad` as it sets local
      // state. @todo explore ways to bring that listener into `GPTSetup`
      googletag.pubads().addEventListener('slotOnload', handleSlotOnLoad);
      googletag.pubads().addEventListener('slotRequested', handleSlotRequested);
      googletag
        .pubads()
        .addEventListener('slotRenderEnded', handleSlotRenderEndedEvent);
      googletag
        .pubads()
        .addEventListener('slotResponseReceived', handleSlotResponseReceived);
      googletag
        .pubads()
        .addEventListener(
          'slotVisibilityChanged',
          handleSlotVisibilityChangedEvent,
        );
      googletag
        .pubads()
        .addEventListener('impressionViewable', handleImpressionViewableEvent);
    });

    return () => {
      googletag.cmd.push(function () {
        googletag.pubads().removeEventListener('slotOnload', handleSlotOnLoad);
        googletag
          .pubads()
          .removeEventListener(
            'slotVisibilityChanged',
            handleSlotVisibilityChangedEvent,
          );
        googletag
          .pubads()
          .removeEventListener('slotRequested', handleSlotRequested);
        googletag
          .pubads()
          .removeEventListener('slotRenderEnded', handleSlotRenderEndedEvent);
        googletag
          .pubads()
          .removeEventListener(
            'slotResponseReceived',
            handleSlotResponseReceived,
          );
        googletag
          .pubads()
          .removeEventListener(
            'impressionViewable',
            handleImpressionViewableEvent,
          );
      });
    };
  }, []);

  useResizeAds();

  return null;
};

export default withErrorBoundary(GPTSetup, {
  FallbackComponent: NoUiErrorFallback,
});
