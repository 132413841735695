/**
 * Returns a googletag.Slot for the provided divId
 *
 * @function getSlotByDivId
 * @param {string} divId
 *
 * @returns {Slot} Googletag slot
 */
const getSlotByDivId = (divId: string): googletag.Slot | null => {
  const slots = googletag.pubads().getSlots();

  for (let i = 0; i < slots.length; i++) {
    if (slots[i].getSlotElementId() === divId) {
      return slots[i];
    }
  }

  return null;
};

export default getSlotByDivId;
