import { EventElementId } from '@/constants';

const linkEvent = ({ detail }: CustomEvent) => {
  const id = detail.id || detail.location;

  // TODO: extract to utility?
  if (id === EventElementId.GlobalFooter_OneTrustAnchorTag) {
    window.OneTrust.ToggleInfoDisplay();
  }
};

export default linkEvent;
