import { env, Feature } from '@/config';
import { AdTargetingKeys } from '@/constants/adTargeting';
import useStore from '@/store';

import clearGptSetInterval from '@/utils/ads/googlePublisherTag/clearGptSetInterval';
import hasRefreshExclusions, {
  shouldBeRefreshed,
} from '@/utils/ads/googlePublisherTag/hasRefreshExclusions';
import { adLog } from '@/utils/grafana/adsTrace';

import { setRefreshKeyValue } from '../setRefreshKeyValue';

const handleImpressionViewableEvent = (
  event: googletag.events.ImpressionViewableEvent,
) => {
  const { slot } = event;
  const slotId = slot.getSlotElementId();
  const adPath = slot.getAdUnitPath();
  const impressionTime = new Date();

  if (Feature.AdRefresh) {
    // Clear all instances of `setInterval`. We need to clear all intervals as
    // they are set per ad and an impression should restart the interval
    clearGptSetInterval(slotId);

    // Once we have an impression, start an interval to refresh the ad only if
    // the exclusions do not apply
    const refreshIntervalId = setInterval(() => {
      if (!hasRefreshExclusions(slot)) {
        googletag.cmd.push(() => {
          setRefreshKeyValue(slot);

          googletag.pubads().refresh([slot]);
        });
      }
    }, env.adRefreshFrequency);

    // Sets the `refreshIntervalId` for a given slot in the store. This will be
    // retrieved in `useTriggerAdRefresh`.
    useStore.getState().setGptRefreshIntervalId(event, refreshIntervalId);

    // Handle refresh when ad has left and re-enters the viewport
    if (shouldBeRefreshed(slot)) {
      window.googletag.cmd.push(() => {
        // If an ad has become viewable and then leaves the viewport, the ad
        // should be refreshed when it re-enters the viewport. The
        // hasBecomeViewable targeting is set to true here. It is subsequently
        // used in the handleSlotVisibilityChangedEvent handler as one of the
        // criteria to determine if an ad should be refreshed.
        slot.setTargeting(AdTargetingKeys.HasBecomeViewable, 'true');
      });
    }
  }

  // Sets the `impressionViewableTime` for a given slot in the store. This will
  // be retrieved in `useTriggerAdRefresh`.
  useStore.getState().setGptImpressionViewableTime(event, impressionTime);

  adLog('ImpressionViewableEvent', { adPath, slotId });
};

export default handleImpressionViewableEvent;
