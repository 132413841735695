import isProd from '@/utils/isProd';

export const onetrustDomainScriptId = isProd
  ? '2270999f-ea21-46c8-a780-e2e3426a1650'
  : '2270999f-ea21-46c8-a780-e2e3426a1650-test';

export const GdprCategories = {
  ADVERTISEMENT: 'Advertisement',
  ESSENTIAL: 'Essential',
  MEASUREMENT: 'Measurement',
  PERSONALIZATION: 'Personalization',
  SOCIAL: 'Social',
};

export const CategoryMapping = {
  [GdprCategories.ADVERTISEMENT]: 'C0004',
  [GdprCategories.ESSENTIAL]: 'C0001',
  [GdprCategories.MEASUREMENT]: 'C0002',
  [GdprCategories.PERSONALIZATION]: 'C0003',
  [GdprCategories.SOCIAL]: 'C0005',
};

// note: GB is not part of the EU, but has similar GDPR law so we treat them as EU
export const EuCountryCodes = [
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'HR', // Croatia
  'CY', // Cyprus
  'CZ', // Czech Republic
  'DK', // Denmark
  'EE', // Estonia
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GB', // Great Britain
  'GR', // Greece
  'HU', // Hungary
  'IE', // Ireland
  'IT', // Italy
  'LV', // Latvia
  'LT', // Lithuania
  'LU', // Luxembourg
  'MT', // Malta
  'NL', // Netherlands
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SK', // Slovakia
  'SI', // Slovenia
  'ES', // Spain
  'SE', // Sweden
];

export const extendedNoticeStates = ['CA', 'CO', 'CT', 'VA', 'UT'];
