import { useEffect, useMemo, useState } from 'react';
import { useBreakpoint } from '@motortrend/ids';
import debounce from 'debounce';

export const RESIZE_DEBOUNCE_INTERVAL = 500;

const mapBreakpoint = (breakpoint: string | boolean) => {
  // IDS has sm, md, lg, xl, 2xl
  // MT ads are mobile, tablet, laptop, desktop
  // Ignore 2xl and treat it as xl/desktop
  return breakpoint === '2xl' ? 'xl' : breakpoint;
};

const useResizeAds = () => {
  const currentBreakpoint = mapBreakpoint(useBreakpoint());
  const [previousBreakpoint, setPreviousBreakpoint] =
    useState(currentBreakpoint);

  const resizeAds = useMemo(
    () =>
      debounce(() => {
        window.googletag = window.googletag || { cmd: [] };
        window.googletag.cmd.push(function () {
          const slots = window.googletag.pubads().getSlots();
          window.googletag.pubads().refresh(slots, { changeCorrelator: false });
        });
      }, RESIZE_DEBOUNCE_INTERVAL),
    [],
  );

  useEffect(() => {
    if (currentBreakpoint === previousBreakpoint) {
      return;
    }
    resizeAds();
    setPreviousBreakpoint(currentBreakpoint);
  }, [currentBreakpoint, previousBreakpoint, resizeAds]);
};

export default useResizeAds;
