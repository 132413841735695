import { adLog } from '@/utils/grafana/adsTrace';

const handleSlotOnLoad = (event: googletag.events.SlotOnloadEvent) => {
  const slotId = event.slot.getSlotElementId();
  const adPath = event.slot.getAdUnitPath();

  adLog('SlotOnLoad', { adPath, slotId });
};

export default handleSlotOnLoad;
