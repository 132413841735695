'use client';

import { useEffect, useRef, type FunctionComponent } from 'react';
import { usePathname } from 'next/navigation';
import useStore from '@/store';
import { withErrorBoundary } from 'react-error-boundary';

import { NoUiErrorFallback } from '@/components/ErrorFallback';

const Navigation: FunctionComponent = () => {
  const { setNavigation } = useStore();
  const pathname = usePathname();
  const previousPathnameRef = useRef(pathname);

  useEffect(() => {
    if (pathname !== previousPathnameRef.current) {
      setNavigation({ previousPath: previousPathnameRef.current ?? '' });
      previousPathnameRef.current = pathname;
    }
  }, [pathname, setNavigation]);

  return null;
};

export default withErrorBoundary(Navigation, {
  FallbackComponent: NoUiErrorFallback,
});
