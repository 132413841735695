'use client';

import { useEffect, type FunctionComponent } from 'react';

import { type ListenedEvents } from './EventListener.props';
import linkEvent from './events/linkEvent';

const LISTENED_EVENTS = {
  link: linkEvent,
} as ListenedEvents;

const EventListener: FunctionComponent = () => {
  useEffect(() => {
    const unsubscribers = Object.keys(LISTENED_EVENTS).map((eventName) => {
      window.addEventListener(
        eventName,
        LISTENED_EVENTS[eventName] as (e: Event) => void,
      );
      return () =>
        window.removeEventListener(
          eventName,
          LISTENED_EVENTS[eventName] as (e: Event) => void,
        );
    });

    return () => unsubscribers.forEach((unsubscribe) => unsubscribe());
  }, []);

  return null;
};

export default EventListener;
