'use client';

import { useEffect } from 'react';

import { GdprCategories } from '@/utils/onetrust/constants';
import loadScript from '@/utils/onetrust/loadScript';

const Quantcast = () => {
  useEffect(() => {
    try {
      const quantcastURL = `${
        document.location.protocol === 'https:'
          ? 'https://secure'
          : 'http://edge'
      }.quantserve.com/quant.js`;

      loadScript(quantcastURL, [GdprCategories.MEASUREMENT]);
    } catch (error) {
      console.error('Error injecting Quantcast script:', error);
    }
  }, []);

  return null;
};

export default Quantcast;
