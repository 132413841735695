import { cloneElement, isValidElement, ReactElement, ReactNode } from 'react';

type ReplacementValue = ReactNode;

/**
 * This function allows us to have placeholders in our /translations files which will be swapped for the real text or React elements later.
 * This way we can inject both strings and React elements in the middle of translation strings, avoiding the need for multiple strings for a block of text.
 *
 * @param {string} inputString - The string with placeholders.
 * @param {Record<string, ReplacementValue>} variables - An object containing the values or React elements to replace the placeholders.
 * @returns {(string | ReactElement)[] | string} - Either a string (if all replacements are strings/numbers) or an array of strings and React elements with placeholders replaced. If a placeholder does not have a corresponding value in the variables object, it is left as is.
 * @throws {Error} - Throws an error if the replacement process encounters an issue.
 */
function replacePlaceholders<T extends Record<string, ReplacementValue>>(
  inputString: string,
  variables: T,
): T extends Record<string, string | number | null | undefined>
  ? string
  : (string | ReactElement)[] {
  const hasReactElements = Object.values(variables).some(isValidElement);

  if (!hasReactElements) {
    return replaceStrings(
      inputString,
      variables as Record<string, string | number | null | undefined>,
    ) as T extends Record<string, string | number | null | undefined>
      ? string
      : never;
  }

  return replaceReactElements(inputString, variables) as T extends Record<
    string,
    string | number | null | undefined
  >
    ? never
    : (string | ReactElement)[];
}

const replaceStrings = (
  inputString: string,
  variables: Record<string, string | number | null | undefined>,
): string => {
  return inputString.replace(/{(\w+)}/g, (match, variable) => {
    const replacement = variables[variable];

    if (replacement === undefined || replacement === null) {
      return match;
    }

    return String(replacement);
  });
};

const replaceReactElements = (
  inputString: string,
  variables: Record<string, ReplacementValue>,
): (string | ReactElement)[] => {
  const parts = inputString.split(/({(?:\w+)})/g);
  return parts.map((part, index) => {
    const match = part.match(/{(\w+)}/);
    if (match) {
      const key = match[1];
      const replacement = variables[key];
      if (isValidElement(replacement)) {
        return cloneElement(replacement, { key: `${key}-${index}` });
      }
      if (replacement === undefined || replacement === null) {
        return part;
      }
      return String(replacement);
    }
    return part;
  });
};

export default replacePlaceholders;
