import hasWindow from '@/utils/hasWindow';

import { getGdprClassName } from './getGdprClassName';

type UrlMap = { [url: string]: Promise<void> | void };
const loadedUrls: UrlMap = {};

// Loads scripts dynamically.
// If `cookieCategory` is passed, script will be loaded via OneTrust
const loadScript = (
  url: string,
  cookieCategory?: string[],
  attributes: { [key: string]: string | boolean } = { async: true },
) => {
  if (loadedUrls[url]) {
    return loadedUrls[url];
  }

  const isOneTrustDefined = hasWindow && typeof window.OneTrust !== 'undefined';
  const isCookieCategoryValid =
    Array.isArray(cookieCategory) && cookieCategory.length > 0;
  const gdprClassName = isCookieCategoryValid
    ? getGdprClassName(cookieCategory)
    : '';

  const shouldLoadViaOneTrust = isCookieCategoryValid && isOneTrustDefined;

  if (shouldLoadViaOneTrust) {
    loadedUrls[url] = new Promise((resolve) => {
      window.OneTrust.InsertScript(
        url,
        'head',
        resolve,
        undefined,
        gdprClassName,
        true,
      );
    });
    return loadedUrls[url];
  }

  loadedUrls[url] = new Promise((resolve, reject) => {
    const scriptTag = document.createElement('script');
    document.getElementsByTagName('head')[0].appendChild(scriptTag);

    scriptTag.onload = () => resolve();
    scriptTag.onerror = () => reject();

    // Handle instances where OneTrust is not yet loaded
    // by tagging script with GDPR class
    if (isCookieCategoryValid && gdprClassName) {
      scriptTag.type = 'text/plain';
      scriptTag.setAttribute('class', gdprClassName);
    }

    // TODO: probably make this a util
    Object.keys(attributes).forEach((key) => {
      const value = attributes[key];

      if (typeof value === 'boolean') {
        if (!value) {
          return;
        }

        scriptTag.setAttribute(key, key);
        return;
      }

      scriptTag.setAttribute(key, value);
    });

    scriptTag.src = url;
  });

  return loadedUrls[url];
};

export default loadScript;
