export function setSessionData(key: string, value: string): void {
  try {
    document.cookie = `${key}=${encodeURIComponent(value)}; path=/;`;
  } catch (e) {
    // console.warn('Cookies are disabled, falling back to localStorage');
    localStorage.setItem(key, value);
  }
}

export function getSessionData(key: string): string | null {
  const nameEQ = key + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) {
      return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
  }
  // console.warn('Cookies are disabled, falling back to localStorage');
  return localStorage.getItem(key);
}
