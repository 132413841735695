'use client';

import { useEffect } from 'react';
import { env } from '@/config';
import { nitrous, type NitrousConfig } from '@motortrend/nitrous-web-sdk';

import pjson from '../package.json';

const { version: appVersion } = pjson;

const useNitrous = () => {
  useEffect(() => {
    try {
      if (nitrous.initialized) {
        // Note that in dev mode useEffects are run twice
        return;
      }

      const apiKey = process.env.NEXT_PUBLIC_MPARTICLE_API_KEY as string;
      const nitrousEnv = process.env
        .NEXT_PUBLIC_NITROUS_ENV as NitrousConfig['env'];
      nitrous.init(apiKey, {
        appName: env.mParticle.appName as NitrousConfig['appName'],
        appVersion,
        env: nitrousEnv,
        logLevel: env.mParticle.logLevel as NitrousConfig['logLevel'],
      });
    } catch (e) {
      console.error(e);
    }
  }, []);
};

export default useNitrous;
