import {
  additionalBotUserAgents,
  botUserAgentSubstrings,
  nextJsBotSubstrings,
} from '@/constants/botUserAgents';
import { isbotMatch } from 'isbot';

/**
 * Checks user agent for bot traffic
 *
 * NOTE: This utility can only be called in client-side components.
 *
 * @returns {boolean} - Returns true if user agent is considered a bot, indicating bot traffic.
 */
const isBotUserAgent = (): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }

  try {
    const userAgent = navigator.userAgent;

    if (!userAgent) {
      return false;
    }

    if (isbotMatch(userAgent)) {
      return true;
    }

    const userAgentLowerCase = userAgent.toLowerCase();
    const combinedBotUserAgents = [
      ...botUserAgentSubstrings,
      ...nextJsBotSubstrings,
      ...additionalBotUserAgents,
    ].map((bot) => bot.toLowerCase());

    return combinedBotUserAgents.some((bot) =>
      userAgentLowerCase.includes(bot),
    );
  } catch (error) {
    console.error('Error checking user agent:', error);
    return false;
  }
};

export default isBotUserAgent;
