import { adLog } from '@/utils/grafana/adsTrace';

const handleSlotRenderEndedEvent = (
  event: googletag.events.SlotRenderEndedEvent,
) => {
  const { isBackfill, isEmpty, size, slot } = event;
  const eventSlotId = slot.getSlotElementId();
  const adPath = slot.getAdUnitPath();

  adLog('SlotRenderEnded', {
    adPath,
    isBackfill,
    isEmpty,
    size,
    slotId: eventSlotId,
  });
};

export default handleSlotRenderEndedEvent;
