'use client';

import { useEffect } from 'react';
import { env } from '@/config';

import loadScript from '@/utils/onetrust/loadScript';

const PrismTag = () => {
  useEffect(() => {
    try {
      loadScript(env.prism.url);
    } catch (error) {
      console.error('Error injecting Prism script:', error);
    }
  }, []);

  return null;
};

export default PrismTag;
