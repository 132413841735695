import hasWindow from '@/utils/hasWindow';

const initGoogletag = () => {
  if (!hasWindow) {
    return;
  }

  // Sets window.googletag equal to itself if it already exists or initialize an
  // object with an empty CommandArray `cmd`
  window.googletag = window.googletag || { cmd: [] };
  window.mraid = {};
};

export default initGoogletag;
