'use client';

import { useEffect, type FunctionComponent } from 'react';

import { type NoUiErrorFallbackProps } from './NoUiErrorFallback.props';

const NoUiErrorFallback: FunctionComponent<NoUiErrorFallbackProps> = ({
  error,
}) => {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return <></>;
};

export default NoUiErrorFallback;
