/**
 * Sets the 'refresh' key to `value` for a specific ad slot. Should be used
 * inside of `cmd.push()`. See {@link https://motortrendgroup.atlassian.net/browse/NTW-2045}
 *
 * @function setRefreshKeyValue
 * @param {googletag.Slot} slot
 * @param {'true' | 'false'} value
 *
 * @returns {void}
 */
export const setRefreshKeyValue = (
  slot: googletag.Slot,
  value: 'true' | 'false' = 'true',
): void => {
  slot.setTargeting('refresh', value);
};
