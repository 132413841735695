import { env } from '@/config';
import {
  getWebInstrumentations,
  initializeFaro,
  type LogEvent,
} from '@grafana/faro-web-sdk';

import replacePlaceholders from '@/utils/replacePlaceholders';

import { mark } from '../ads/performanceUtil';

let faroIsInitialized = false;
let grafanaInstance = undefined;

const initializeGrafana = function () {
  mark();
  const { apiKey, appName, trace, url, samplingRate } = env.grafana;

  if (!faroIsInitialized) {
    grafanaInstance = initializeFaro({
      app: {
        environment: 'production',
        name: appName,
        version: '1.0.0',
      },
      sessionTracking: {
        samplingRate: samplingRate,
      },
      beforeSend(item) {
        // suppressing trace calls & warning / info logs to grafana
        if (!trace && item?.type === 'trace') {
          return null;
        }
        if (
          ((item.payload as LogEvent)?.level === 'warn' ||
            (item.payload as LogEvent)?.level === 'info') &&
          !trace
        ) {
          return null;
        }
        return item;
      },
      instrumentations: [...getWebInstrumentations()],
      url: replacePlaceholders(url, { apiKey }),
    });
    if (window && !Object.prototype.hasOwnProperty.call(window, 'faro')) {
      // Use `Object.defineProperty` to set `faro` on the window object
      Object.defineProperty(window, 'faro', {
        value: grafanaInstance,
        writable: true,
        configurable: true,
        enumerable: true,
      });
    }

    faroIsInitialized = true;
  }
};

export default initializeGrafana;
