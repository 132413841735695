import { Feature } from '@/config';
import { AdTargetingKeys } from '@/constants/adTargeting';

import { adLog } from '@/utils/grafana/adsTrace';
import { setSessionData } from '@/utils/sessionData';

const handleSlotRequested = (event: googletag.events.SlotRequestedEvent) => {
  const slot = event.slot;
  const slotId = slot.getSlotElementId();
  const adPath = slot.getAdUnitPath();

  setSessionData('mtAdRequested', 'true');

  adLog('SlotRequested', { adPath, slotId });

  if (Feature.AdRefresh) {
    // The business logic below is meant to service the following workflow:
    // If an advertisement has become viewable and then leaves the viewport, the advertisement should be refreshed when it re-enters the viewport.
    // The hasBecomeViewable targeting key is set to true in handleImpressionViewableEvent.
    // It is subsequently used in the handleSlotVisibilityChangedEvent handler as one of the criteria to determine if an advertisement should be refreshed.
    // The hasLeftViewportOnce targeting key is set to true in handleSlotVisibilityChangedEvent.
    // It is also subsequently used in the handleSlotVisibilityChangedEvent handler as one of the criteria to determine if an advertisement should be refreshed.
    // This hook will be re-run whenever a slot is refreshed and a new ad request is made,
    // so we want to clear any existing hasBecomeViewable and hasLeftViewportOnce targeting values on the slot,
    // to make sure each refreshed ad starts with a clean slate.
    if (
      slot.getTargeting(AdTargetingKeys.HasBecomeViewable).indexOf('true') > -1
    ) {
      window.googletag.cmd.push(() => {
        slot.clearTargeting(AdTargetingKeys.HasBecomeViewable);
      });
    }

    if (
      slot.getTargeting(AdTargetingKeys.HasLeftViewportOnce).indexOf('true') >
      -1
    ) {
      window.googletag.cmd.push(() => {
        slot.clearTargeting(AdTargetingKeys.HasLeftViewportOnce);
      });
    }
  }
};

export default handleSlotRequested;
