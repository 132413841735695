import { useCallback, useEffect, useMemo } from 'react';
import { env, Feature } from '@/config';
import useStore from '@/store';
import throttle from 'lodash/throttle';

import clearGptSetInterval from '@/utils/ads/googlePublisherTag/clearGptSetInterval';
import getSlotByDivId from '@/utils/ads/googlePublisherTag/getSlotByDivId';
import hasRefreshExclusions, {
  shouldBeRefreshed,
} from '@/utils/ads/googlePublisherTag/hasRefreshExclusions';
import { setRefreshKeyValue } from '@/utils/ads/googlePublisherTag/setRefreshKeyValue';

import { GooglePublisherTagStateKey, SubStateKey } from '@/store/constants';

const SCROLL_THROTTLE_MS = 200; // Throttle scroll events to once every 200ms

/**
 * Hook that defines an event listener and callback function for `scrollend`.
 *
 * @function useTriggerAdRefresh
 * @returns {void}
 */
const useTriggerAdRefresh = (): void => {
  const { setLastScrollTime } = useStore();
  // Gets the state object of all the ads (by divId) that we have have an
  // impressionViewable event for, set in `handleImpressionViewableEvent`
  const getImpressionViewableState = useCallback(() => {
    return (
      useStore.getState()[GooglePublisherTagStateKey][
        SubStateKey.ImpressionViewableTime
      ] || {}
    );
  }, []);

  /**
   * The callback passed to the `scrollend` event listener
   * If scroll event (`scrollTime`) happens 5 or more seconds after
   * impressionViewable event was triggered (`impressionViewableState`), iterate
   * over viewed ads and call refresh.
   *
   * @function refreshAds
   * @returns {void}
   */
  const refreshAds = useCallback(() => {
    const scrollTime = new Date();
    const impressionViewableState = getImpressionViewableState();

    googletag.cmd.push(() => {
      // Will keep track of ads (by divId) that we have refreshed.
      const refreshedAdIds: string[] = [];

      // Iterate over `impressionViewableState` object, extracting the key
      // (`divId`) and timestamp (`value`)
      for (const [divId, value] of Object.entries(impressionViewableState)) {
        // Convert `value` to milliseconds
        const timeViewed = new Date(value as Date).getTime();

        const slot = getSlotByDivId(divId);

        // Do we have a slot and did this scroll event occur
        // `adRefeshFrequencyAlt` (default is 5 in ms) or more seconds after we
        // recevied an `impressionViewable` event?
        if (
          slot &&
          scrollTime.getTime() - timeViewed > env.adRefreshFrequencyAlt
        ) {
          refreshedAdIds.push(divId);

          // Clear all instances of `setInterval`. We need to clear all
          // intervals as they are set per ad and an impression should restart
          // the interval
          clearGptSetInterval(divId);

          // Refresh the ad only if the exclusions do not apply
          if (!hasRefreshExclusions(slot)) {
            setRefreshKeyValue(slot);

            googletag.pubads().refresh([slot]);
          }
        }
      }

      // Clean up: Set the `impressionViewableTime` to undefined to avoid
      // refreshing immediately when an ad re-enters the viewport
      refreshedAdIds.forEach((slotId) => {
        useStore.getState().resetGptImpressionViewableTime(slotId);
      });
    });

    setLastScrollTime(scrollTime);
  }, [setLastScrollTime, getImpressionViewableState]);

  const onScrollEndCallback = useCallback(() => {
    refreshAds();
  }, [refreshAds]);

  // Safari fix
  // Throttled scroll callback
  const onScrollCallback = useMemo(
    () =>
      throttle(
        () => {
          refreshAds();
        },
        SCROLL_THROTTLE_MS,
        { leading: true, trailing: true },
      ),
    [refreshAds],
  );

  const onVisibilityChange = useCallback(() => {
    const impressionViewableState = getImpressionViewableState();

    if (!document.hidden) {
      googletag.cmd.push(() => {
        const adIdsToRefresh: string[] = [];

        for (const divId of Object.keys(impressionViewableState)) {
          const slot = getSlotByDivId(divId);

          if (slot && shouldBeRefreshed(slot)) {
            adIdsToRefresh.push(divId);

            setRefreshKeyValue(slot);

            googletag.pubads().refresh([slot]);
          }
        }

        // Clean up: Reset the impressionViewableTime for refreshed ads
        adIdsToRefresh.forEach((divId) => {
          useStore.getState().resetGptImpressionViewableTime(divId);
        });
      });
    }
  }, [getImpressionViewableState]);

  // Set up event listeners. Using `scrollend` but fallback to `scroll` because
  // some browsers (I'm lookin at you, Safari) don't react to `scrollend`
  useEffect(() => {
    if (typeof window === 'undefined') return;

    window.addEventListener('visibilitychange', onVisibilityChange);

    // None of the above will happen unless this feature is enabled
    if (Feature.AdRefresh) {
      const isScrollEndSupported = 'onscrollend' in window;
      const scrollEvent = isScrollEndSupported ? 'scrollend' : 'scroll';
      const scrollCallback = isScrollEndSupported
        ? onScrollEndCallback
        : onScrollCallback;

      window.addEventListener(scrollEvent, scrollCallback);

      return () => {
        window.removeEventListener(scrollEvent, scrollCallback);
        if (onScrollCallback.cancel) {
          onScrollCallback.cancel();
        }
      };
    }
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [onScrollCallback, onScrollEndCallback, onVisibilityChange]);
};

export default useTriggerAdRefresh;
