import { adLog } from '@/utils/grafana/adsTrace';

const handleSlotResponseReceived = (
  event: googletag.events.SlotResponseReceived,
) => {
  const slotId = event.slot.getSlotElementId();
  const adPath = event.slot.getAdUnitPath();
  /**
   * Note that getResponseInformation() is incorrectly typed and indeed has many
   * more fields than the ones defined by @types/google-publisher-tag
   */
  const {
    advertiserId,
    campaignId,
    creativeId,
    creativeTemplateId,
    lineItemId,
  } = event.slot.getResponseInformation() || {};

  adLog('SlotResponseReceived', {
    adPath,
    advertiserId,
    campaignId,
    creativeId,
    creativeTemplateId,
    lineItemId,
    slotId,
  });
};

export default handleSlotResponseReceived;
