'use client';

import { useEffect, useState, type FunctionComponent } from 'react';
import Script from 'next/script';

import admiralScriptAction from '@/actions/admiralTagAction/admiralTagAction';

const AdmiralAdBlockerTag: FunctionComponent = () => {
  const [scriptContent, setScriptContent] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      const data = await admiralScriptAction();
      setScriptContent(data || '');
    };

    fetchData().catch(console.error);
  }, []);

  return scriptContent ? (
    <Script
      dangerouslySetInnerHTML={{ __html: scriptContent }}
      id="admiral"
      onError={(e: Error) => {
        console.error('Admiral failed to load', e);
      }}
      type="text/javascript"
    />
  ) : null;
};

export default AdmiralAdBlockerTag;
