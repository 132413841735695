import useStore from '@/store';

import { GooglePublisherTagStateKey, SubStateKey } from '@/store/constants';

/**
 * Runs clearInterval on each entry in `GooglePublisherTag.refreshIntervalId`
 *
 * @function clearGptSetInterval
 *
 * @returns {undefined}
 */
const clearGptSetInterval = (divId?: string): undefined => {
  // Retrieve the interval IDs that we set in `handleImpressionViewableEvent`
  const refreshIntervalIds =
    useStore.getState()[GooglePublisherTagStateKey][
      SubStateKey.RefreshIntervalId
    ] || {};

  // Clear single instance of `setInterval`
  if (divId) {
    clearInterval(refreshIntervalIds[divId]);

    return;
  }

  // Since the user scrolled, we can cancel all instances of `setInterva`, ad
  // will refresh now instead of the default interval
  Object.entries(refreshIntervalIds).forEach(([, intervalId]) => {
    clearInterval(intervalId);
  });

  return;
};

export default clearGptSetInterval;
