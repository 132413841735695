import { AdTargetingKeys } from '@/constants/adTargeting';

/**
 * Checks if the provided `slot` is targeted using values that indicate no
 * refresh should occur
 *
 * @function hasRefreshExclusions
 * @param {googletag.Slot} slot
 *
 * @returns {boolean}
 */
const hasRefreshExclusions = (slot: googletag.Slot): boolean => {
  if (
    // Slot is currently NOT visible
    slot.getTargeting(AdTargetingKeys.IsCurrentlyVisible).indexOf('false') >
      -1 ||
    slot.getTargeting('sponsored').indexOf('true') > -1 ||
    slot.getTargeting('sect').indexOf('video') > -1 ||
    slot.getTargeting('subs').indexOf('rankings') > -1 ||
    slot.getTargeting('k').indexOf('buyersguide') > -1 ||
    slot.getTargeting('fluid').indexOf('bottom_adhesion') > -1 ||
    slot.getTargeting('sect').indexOf('homepage') > -1
  ) {
    return true;
  }

  return false;
};

export default hasRefreshExclusions;

/**
 * Checks if the provided `slot` is targeted using values that indicate no
 * refresh should occur. Similar to the `hasRefreshExclusions` function, but
 * excludes a check for isCurrentlyVisible
 *
 * @function hasRefreshExclusions
 * @param {googletag.Slot} slot
 *
 * @returns {boolean}
 */
export const shouldBeRefreshed = (slot: googletag.Slot): boolean => {
  if (
    slot.getTargeting('sponsored').indexOf('true') > -1 ||
    slot.getTargeting('sect').indexOf('video') > -1 ||
    slot.getTargeting('subs').indexOf('rankings') > -1 ||
    slot.getTargeting('k').indexOf('buyersguide') > -1 ||
    slot.getTargeting('fluid').indexOf('bottom_adhesion') > -1 ||
    slot.getTargeting('sect').indexOf('homepage') > -1
  ) {
    return false;
  }

  return true;
};
