import { CategoryMapping } from './constants';

export const getGdprClassName = (categoryNames: string[]): string | void => {
  const categoryIds = categoryNames.map((categoryName) => {
    return CategoryMapping[categoryName];
  });

  const normalizedCategoryIds = categoryIds.filter((x) => !!x);
  const categoryIdString = normalizedCategoryIds.join('-');

  const gdprClassName = categoryIdString
    ? `optanon-category-${categoryIdString}`
    : undefined;

  return gdprClassName;
};
