'use client';

import { useState, type ReactNode } from 'react';
import {
  QueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from '@tanstack/react-query';

export function QueryClientProvider({ children }: { children: ReactNode }) {
  const [queryClient] = useState(() => new QueryClient());

  return (
    /* QueryClientProvider is a component from React Query that sets up the context for managing and caching asynchronous data fetching throughout the application.

    - It wraps the entire application (represented by {children}) to provide access to the React Query functionality to all components.
    - The 'client' prop is passed the 'queryClient' instance, which is created using useState to ensure it persists across re-renders.
    - This setup allows all child components to use React Query hooks (like useQuery and useMutation) for efficient data fetching, caching, and state management.
    - By wrapping the app with QueryClientProvider, you're setting up a central store for all query data, enabling features like automatic refetching, cache invalidation, and optimistic updates.

    For more detailed information, refer to the official React Query documentation:
    https://tanstack.com/query/latest/docs/react/overview */
    <ReactQueryClientProvider client={queryClient}>
      {children}
    </ReactQueryClientProvider>
  );
}
